@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 1024px) {
  /* Large devices */
  .grid-cols-4 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 768px) {
  /* Medium devices */
  .grid-cols-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

div.row.width-auto.display-only-web.mb-m
  > div.column.parent-width.position-relative.undefined {
  width: auto;
}
div.row.width-auto.display-only-web.mb-m
  > div.column
  > div.row.border-radius-m.text-wrap-none.p-v-s.dk-button-hover.bg-white.border-m.mr-r {
  padding: 10.5px;
}
