.assign-owner-popup .popup-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.auto-element-card {
  .auto-element-control {
    display: none;
  }

  &:hover .auto-element-control {
    display: flex;
  }
}

.org-users {
  #dk-list-picker-2 {
    width: 200px !important;
  }
}

/* Hiding DKInputForm default header */
.deal-stage-trigger-form {
  &>div.row.justify-content-between:first-of-type {
    display: none;
  }
}

.email-dklistpicker2 {
  max-height: 300px;

  .hide-scroll-bar.mt-s {
    max-height: 165px !important;
  }
}

.preview-email-template {
  #dk-page-canvas-component {
    z-index: 0 !important;
  }

  a {
    pointer-events: none !important;
  }

  #emailContactDetail {
    pointer-events: none;
  }

  #canvas-holder {
    padding: 0 !important;

    #footer-buttons {
      display: none;
    }
  }
}

div.step-action-wrapper {
    &:hover {
      .step-actions-list {
        visibility: visible;
      }
    }

    div.step-actions-list {
      visibility: hidden;
    }
}
div.bg-white.circle.position-absolute.shadow-s{
  top:1px !important;
}