.field-highlighter {
    border: 1px blue;
    border-style: dashed;
}

div#form-row {
    #form-actions {
        visibility: hidden;
    }

    &:hover {
        font-weight: 600;
        transform: scale(1.01);
        box-shadow: 10px;

        #form-actions {
            visibility: visible;
        }
    }
}

div#fb-form-list-wrap {

    div.row.width-auto.display-only-web>div.column.parent-width.position-relative.undefined {
        width: auto;
    }

    div.row.width-auto.display-only-web>div.column>div.row.border-radius-m.text-wrap-none.p-v-s.dk-button-hover.bg-white.border-m.mr-r {
        margin: 0;
        padding: 10.5px;
    }

}

div.sub-field-row {
    img.subfield-remove {
        visibility: hidden;
    }

    &:hover {
        img.subfield-remove {
            visibility: visible;
        }
    }
}