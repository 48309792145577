.main-main {
}

.main-page {
}

@font-face {
  font-family: "geist-sans";
  src: local("GeistVF"),
    url("./fonts/GeistVF.woff") format("woff");
}

.font-geist-sans {
  font-family: "geist-sans"; 
}

.font-instrument-serif {
  font-family: "Instrument Serif", serif;
  font-weight: 400;
  font-style: normal;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Translucent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.modal-content {
  background-color: white;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1001; /* Above the overlay */
  animation: fade-in 0.6s; /* Optional: Fade-in animation */
}

.slide-animation {
  position: relative;
  right: -100px; /* Start off-screen */
  transition: right 0.5s ease-in-out; /* Transition effect */
}

.slide-animation.active {
  right: 0; /* Move into view */
}

@keyframes slideIn {
  from {
    transform: translateX(-100%); /* Start off-screen */
  }
  to {
    transform: translateX(0); /* End at original position */
  }
}

.slide-in-animation {
  animation: slideIn 0.5s forwards; /* Apply animation */
}

.dotted-background {
  background: rgb(250, 250, 250);
  background-image: radial-gradient(rgb(226, 226, 226) 1px, transparent 0);
  background-size: 10px 10px;
  /* background-position: -19px -19px; */
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-animation {
  animation: fade-in 0.5s ease; /* Adjust duration and easing as needed */
}

.fw-500 {
  font-weight: 500;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ffc64a;
  border-radius: 10px;
}
.custom-scrollbar{
      mask-image: linear-gradient(to top, transparent, black),
          linear-gradient(to left, transparent 17px, black 17px);
      mask-size: 100% 20000px;
      mask-position: left bottom;
      -webkit-mask-image: linear-gradient(to top, transparent, black),
          linear-gradient(to left, transparent 17px, black 17px);
      -webkit-mask-size: 100% 20000px;
      -webkit-mask-position: left bottom;
      transition: mask-position 0.3s, -webkit-mask-position 0.3s;
  }
  .custom-scrollbar:hover {
  -webkit-mask-position: left top;
  }

