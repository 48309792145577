body {
  margin: 0;
  font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI",
  Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji",
  "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.text-12-regular {
  font-size: .75rem;
  line-height: 1.25rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.code-block {
  pre code {
    display: block;
    background: none;
    white-space: pre;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    max-width: 100%;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
}

@import "./variables.scss";
@import "./mixins.scss";
@import "./theme.scss";
@import "./common.scss";
@import "./utility.scss";
@import "./overrides.scss"
