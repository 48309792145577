#gjs {
  height: 100%;
  border: 3px solid #444;
}

.gjs-block {
  width: auto;
  height: auto;
  min-height: auto;
}

#blocks .gjs-blocks-cs {
  background-color: transparent;
}

.editor-btn {
  background-color: #fff;
}

.editor-btn-content {
  display: flex;
  align-items: center;
}
