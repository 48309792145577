/* Webkit browsers: style the scrollbar */
.content {
  overflow-y: hidden;
  padding-right: 10px;
}
.content:hover {
  overflow-y: auto;
  padding-right: 0px;
}

.content::-webkit-scrollbar {
  width: 8px; /* Slim scrollbar */
  height: 8px; /* Slim horizontal scrollbar */
}

.content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray track */
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content::-webkit-scrollbar-thumb {
  background: #888; /* Gray thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
  transition: background-color 0.3s ease;
  display: none;
}

/* Style the scrollbar thumb on hover */
.content::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker gray on hover */
}

/* Remove the arrows by preventing them from being shown */
.content::-webkit-scrollbar-button {
  display: none; /* Hide the top and bottom arrows */
}

/* Firefox scrollbar customization */
.content {
  scrollbar-width: thin; /* Thin scrollbar */
  scrollbar-color: #888 #f1f1f1; /* Thumb color and track color */
  /* Note: Firefox does not support hiding scrollbar buttons */
}

.loading-dots {
  display: inline-block;
  position: relative;
  width: 30px; /* Adjusted width for smaller dots */
  height: 10px; /* Adjusted height for smaller dots */
}

.loading-dots div {
  position: absolute;
  top: 0;
  width: 5px; /* Smaller width */
  height: 5px; /* Smaller height */
  border-radius: 50%;
  background: #888; /* Gray color for the dots */
  animation: loading-dots 1s infinite;
  animation-timing-function: ease-in-out;
}

.loading-dots div:nth-child(1) {
  left: 3px; /* Adjusted position for smaller dots */
  animation-delay: 0s;
}

.loading-dots div:nth-child(2) {
  left: 12px; /* Adjusted position for smaller dots */
  animation-delay: 0.2s;
}

.loading-dots div:nth-child(3) {
  left: 21px; /* Adjusted position for smaller dots */
  animation-delay: 0.4s;
}

@keyframes loading-dots {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
