/* background themes */
.bg-app {
  background-color: $appTheme;
}

.app-bg-color {
  background-color: $appBG;
}

.bg-button,
div.dk-bg-button,
button.dk-bg-button {
  background-color: $buttonTheme;
}

/* TBD */
// .bg-blue {
//   background-color: salmon;
// }

.bg-app2 {
  background-color: rgb(90, 100, 215);
}

/* BOOKS colors
.bg-app {
  background-color: rgb(78, 99, 206);
}
.bg-button {
  background-color: rgb(86, 109, 221);
}
div.dk-bg-button,
button.dk-bg-button {
  background-color: rgb(86, 109, 221);
}
*/

/* Text themes */
.text-app {
  color: $buttonTheme;
}

.text-app-color {
  color: rgb(90, 100, 215);
}

/* Border themes */
.border-app {
  border: 1px solid $appTheme;
}
