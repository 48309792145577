//override tailwind css here
.opacity-5 {
    opacity: .5;
}
img{
    max-width: none;
}
.text-32 {
    font-size: 32px;
    line-height: 2.5rem;
}